import { onMounted } from 'vue';
import { Storage } from '@ionic/storage';

const store = new Storage({name: 'dicstorage'});

export function dicOptions() {
    const loadOptions = async () => {
      await store.create();
    }

    const setOptions = async(key: string, value: string) => {
	const test = await store.set(key, value);
	console.log("setOptions", test, key, value);
    }

    const getOptions = async(key: string) => {
	const value = await store.get(key);
	console.log("getOptions", value, key);
	//app.config.globalProperties.$suchmodus = value;
	return { value };
    }
    
    onMounted(loadOptions);

    //watch(suchmodus, cacheSuchmodus);

    return { setOptions, getOptions, loadOptions }

}
