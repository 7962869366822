
import { IonPage, IonHeader, IonToolbar, IonContent,
         IonSearchbar, IonButton, IonItem, IonLabel, IonList,
         IonSelect, IonSelectOption } from '@ionic/vue';
import { defineComponent } from 'vue';
// https://stackoverflow.com/a/59243562
//import tables from "@/dicdata/my_rg_data_json_short.json";
//import tables from "@/dicdata/rumantschgrischun_data_json.json";
import tablesA from "@/dicdata/a_rg_conj.json";
import tablesB from "@/dicdata/b_rg_conj.json";
import tablesC from "@/dicdata/c_rg_conj.json";
import tablesD from "@/dicdata/d_rg_conj.json";
import tablesE from "@/dicdata/e_rg_conj.json";
import tablesF from "@/dicdata/f_rg_conj.json";
import tablesG from "@/dicdata/g_rg_conj.json";
import tablesH from "@/dicdata/h_rg_conj.json";
import tablesI from "@/dicdata/i_rg_conj.json";
import tablesJ from "@/dicdata/j_rg_conj.json";
import tablesK from "@/dicdata/k_rg_conj.json";
import tablesL from "@/dicdata/l_rg_conj.json";
import tablesM from "@/dicdata/m_rg_conj.json";
import tablesN from "@/dicdata/n_rg_conj.json";
import tablesO from "@/dicdata/o_rg_conj.json";
import tablesP from "@/dicdata/p_rg_conj.json";
import tablesQ from "@/dicdata/q_rg_conj.json";
import tablesR from "@/dicdata/r_rg_conj.json";
import tablesS from "@/dicdata/s_rg_conj.json";
import tablesT from "@/dicdata/t_rg_conj.json";
import tablesU from "@/dicdata/u_rg_conj.json";
import tablesV from "@/dicdata/v_rg_conj.json";
import tablesW from "@/dicdata/w_rg_conj.json";
import tablesX from "@/dicdata/x_rg_conj.json";
import tablesY from "@/dicdata/y_rg_conj.json";
import tablesZ from "@/dicdata/z_rg_conj.json";

import { starOutline, chevronForwardOutline, chevronBackOutline, codeOutline } from 'ionicons/icons';
import { Storage } from '@ionic/storage';
import { dicOptions } from '@/composables/dicOptions';
//import {  watch } from 'vue';
//import { search } from '@metrichor/jmespath';

const store = new Storage({name: 'dicstorage'});
const { getOptions, loadOptions, setOptions } = dicOptions();

let table = tablesA;

export default defineComponent({
  name: 'Tab1',
  components: { IonPage, IonHeader, IonToolbar, IonContent,
         IonSearchbar, IonButton, IonItem, IonLabel, IonList,
         IonSelect, IonSelectOption },
    setup() {
      loadOptions();
      return { starOutline, chevronForwardOutline, chevronBackOutline,
  codeOutline};
  },
    async created() {
      await store.create();
      // https://stackoverflow.com/a/45430612
	await getOptions('suchmodus').then(res =>
	    this.suchmodus = res.value ? res.value : 'wortanfang');
  },
  data() {
	return {
            searchQuery: null,
	    //tables: tables,
            //tablesA: tablesA, tablesB: tablesB,
            dir: 0,
            suchmodus: '',
   }
  },
    computed: {
	resultQuery(this: any) {
	    console.log("resultQuery", this.suchmodus);

            if (this.searchQuery) {
		if(this.searchQuery.toLowerCase().startsWith("a"))  table = tablesA;
		if(this.searchQuery.toLowerCase().startsWith("b"))  table = tablesB;
		if(this.searchQuery.toLowerCase().startsWith("c"))  table = tablesC;
		if(this.searchQuery.toLowerCase().startsWith("d"))  table = tablesD;
		if(this.searchQuery.toLowerCase().startsWith("e"))  table = tablesE;
		if(this.searchQuery.toLowerCase().startsWith("f"))  table = tablesF;
		if(this.searchQuery.toLowerCase().startsWith("g"))  table = tablesG;
		if(this.searchQuery.toLowerCase().startsWith("h"))  table = tablesH;
		if(this.searchQuery.toLowerCase().startsWith("i"))  table = tablesI;
		if(this.searchQuery.toLowerCase().startsWith("j"))  table = tablesJ;
		if(this.searchQuery.toLowerCase().startsWith("k"))  table = tablesK;
		if(this.searchQuery.toLowerCase().startsWith("l"))  table = tablesL;
		if(this.searchQuery.toLowerCase().startsWith("m"))  table = tablesM;
		if(this.searchQuery.toLowerCase().startsWith("n"))  table = tablesN;
		if(this.searchQuery.toLowerCase().startsWith("o"))  table = tablesO;
		if(this.searchQuery.toLowerCase().startsWith("p"))  table = tablesP;
		if(this.searchQuery.toLowerCase().startsWith("q"))  table = tablesQ;
		if(this.searchQuery.toLowerCase().startsWith("r"))  table = tablesR;
		if(this.searchQuery.toLowerCase().startsWith("s"))  table = tablesS;
		if(this.searchQuery.toLowerCase().startsWith("t"))  table = tablesT;
		if(this.searchQuery.toLowerCase().startsWith("u"))  table = tablesU;
		if(this.searchQuery.toLowerCase().startsWith("v"))  table = tablesV;
		if(this.searchQuery.toLowerCase().startsWith("w"))  table = tablesW;
		if(this.searchQuery.toLowerCase().startsWith("x"))  table = tablesX;
		if(this.searchQuery.toLowerCase().startsWith("y"))  table = tablesY;
		if(this.searchQuery.toLowerCase().startsWith("z"))  table = tablesZ;
		
		return table.filter((item: any) => {
		    //	return this.tables.filter((item: any) => {
		    if(this.dir==1 && this.suchmodus != "exakteswort") {
			return Array(this.searchQuery
			    .toLowerCase())
			//.split(" ")
			    .every((v: any) =>  {
				if(this.suchmodus == "wortanfang")
				    return item.RStichwort?.toLowerCase().startsWith(v);
				if(this.suchmodus == "teilstring")
				    return item.RStichwort?.toLowerCase().includes(v);
			    });
		    }
		    if(this.dir==1 && this.suchmodus == "exakteswort") {
			return Array(this.searchQuery
			    .toLowerCase())
			    .every((v: any) =>  {
				return item.RStichwort?.toLowerCase().match('^'+v+'$')
			    });
		    }
		    if(this.dir==0 && this.suchmodus != "exakteswort") {
			return Array(this.searchQuery
			    .toLowerCase())
			    .every((v: any) =>
				item.DStichwort?.toLowerCase().startsWith(v));
		    }
		    if(this.dir==0 && this.suchmodus == "exakteswort") {
			return Array(this.searchQuery
			    .toLowerCase())
			    .every((v: any) =>  {
				return item.DStichwort?.toLowerCase().match('^'+v+'$')
			    });			
		    }
		    if(this.dir==2 && this.suchmodus != "exakteswort") {
			return Array(this.searchQuery
			    .toLowerCase())
			    .every((v: any) =>
				item.DStichwort?.toLowerCase().startsWith(v)
				|| item.RStichwort?.toLowerCase().startsWith(v));
		    }
		    if(this.dir==2 && this.suchmodus == "exakteswort") {
			return Array(this.searchQuery
			    .toLowerCase())
			    .every((v: any) => 
				item.DStichwort?.toLowerCase().match('^'+v+'$')
				|| item.RStichwort?.toLowerCase().match('^'+v+'$'));			
		    }
		}).sort((i1, i2): number => {
		    if(this.dir==0) return i1.DStichwort.localeCompare(i2.DStichwort);
		    if(this.dir==1) return i1.RStichwort.localeCompare(i2.RStichwort);
		    return i1.DStichwort.localeCompare(i2.DStichwort);
		}).splice(0, 5);
	    } else {
		return null; //this.tables.filter;
	    }
	},
    },
  methods: {
      async myset(key: string, value: any) {
	  const gespeichert = await store.get('gespeichert');
	  //const 
	  const valuestr = JSON.stringify(value);
	  //Array(gespeichert).push(JSON.stringify(value));
	  const valstr = gespeichert ?  gespeichert+', '+valuestr : valuestr; 
	  
          const myname = await store.set('gespeichert', valstr);
          console.log("gespeichert", valstr);
        },
	async myget() {
          const myname = await store.get('name');
          console.log("mynameget", myname);
	},
      gotChange(event: any) {
          console.log("focus", event.detail.value);
          this.suchmodus = event.detail.value;
          setOptions("suchmodus", event.detail.value);
      }
  },
 ionViewDidEnter() {
     //async () => { await getOptions('suchmodus').then(res => this.suchmodus = res.value);}
     console.log('Tab1 page did enter');

},
})
